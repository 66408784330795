import Formvcomplaint from "../Component/formulaires/FormComplaint.component";
import { illustration6 } from "../Component/imageassets";
import { App } from "./../App";
import BreadcrumbComponent from "./../Component/breadcrumbComponent";


function ReclamationPage(Props) {
  return (
    <>
      <BreadcrumbComponent titre={"Réclamation"} />
      <div className="container mt-3">
        <div className="row">
          <div className="col-md-5 my-auto mt-6">
            <img className="img-fluid w-60" src={illustration6} alt="" />
          </div>
          <div className="col-md-7 my-auto">
            <Formvcomplaint />
          </div>
        </div>
      </div>


    </>
  );
}
const Reclamation = () => <App component={ReclamationPage} />;

export default Reclamation;
