import "./App.css";
import "./style.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Component/imageassets";
import HeaderComponent from "./Component/header/HeaderComponent";
import FooterComponent from "./Component/footer/FooterComponent";
import { BrowserRouter } from "react-router-dom";
import RouterComponent from "./Route/RouterComponent";
import SimpleForm from './chatbot/ChatBot.component';

export function App(props) {
  let Component = props.component ?? <></>;
  return (
    <div className="App">

      <HeaderComponent></HeaderComponent>

      <Component></Component>

      <FooterComponent></FooterComponent>
      <SimpleForm/>
    </div>
  );
}

const Home = () => (
  <BrowserRouter>
    <RouterComponent />
  </BrowserRouter>
);
export default Home;
