import React from "react";
import { authentification1 } from "../imageassets";
import { Link } from "react-router-dom";
export default class HeaderComponent extends React.Component {
  render() {
    return (
      <header id="site-header" className="fixed-top">

        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-dark stroke">
            <h1>
              <Link className="navbar-brand" to={"/"}>
                <img
                  src={authentification1}
                  alt="logo de Verification.ci"
                  className="img-fluid"
                  width={"100%"}
                />
              </Link>
            </h1>

            <button
              className="navbar-toggler  collapsed bg-gradient"
              type="button"
              data-toggle="collapse"
              data-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon fa icon-expand fa-bars"></span>
              <span className="navbar-toggler-icon fa icon-close fa-times"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav mx-lg-auto">
                <li className="nav-item">
                  <Link className="nav-link" to={"/"}>
                    Accueil <span className="sr-only">(current)</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={"/paiement-du-coupon"}>
                    Acheter un coupon
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={"/consulter-recu"}>
                    Consulter son reçu
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={"/reclamation"}>
                    Réclamation
                  </Link>
                  <span></span>
                </li>

                <li className="nav-item @@contact__active">
                  <Link className="nav-link" to={"/contact"}>
                    Contact
                  </Link>
                </li>
              </ul>
              <a
                href={"https://preprod6.preprod-directverify.in/student/"}
                target={"_blank"}
              >
                <button className="btn btn-style btn-edar2 ">
                  Utiliser le coupon
                </button>
              </a>
            </div>

            <div className="mobile-position">
              {/*  <nav className="navigation">
                        <div className="theme-switch-wrapper">
                            <label className="theme-switch" for="checkbox">
                                <input type="checkbox" id="checkbox"/>
                                <div className="mode-container py-1">
                                    <i className="gg-sun"></i>
                                    <i className="gg-moon"></i>
                                </div>
                            </label>
                        </div>
                    </nav> */}
            </div>
          </nav>
        </div>
      </header>
    );
  }
}
