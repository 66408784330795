import React from "react";
import PartnerComponent from "./../Component/PartnerComponent";
import StepComponent from "./../Component/StepComponent";
import MiddleComponent from "./../Component/MiddleComponent";
import FeatureComponent from "./../Component/FeatureComponent";
import Mainslider from "../Component/Mainslider";
import { App } from "./../App";

function HomePage(props) {
  return (
    <>
      <Mainslider></Mainslider>
      <FeatureComponent></FeatureComponent>
      <MiddleComponent></MiddleComponent>
      <StepComponent></StepComponent>
      <PartnerComponent></PartnerComponent>
    </>
  );
}

const Home = () => <App component={HomePage} />;

export default Home;
