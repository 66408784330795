import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./../pages/homepage";
import ContactPage from './../pages/contactpage';
import Achatcoupon from './../pages/achatcouponpage';
import Reclamation from './../pages/reclamationpage';
import ConsulterRecu from './../pages/recupage';

function RouterComponent() {
  return (
    <Routes>
      <Route element={<HomePage/>} path="/"></Route>
      <Route element={<ContactPage/>} path="/contact"></Route>
      <Route element={<Achatcoupon/>} path="/paiement-du-coupon"></Route>
      <Route element={<Reclamation/>} path="/reclamation"></Route>
      <Route element={<ConsulterRecu/>} path="/consulter-recu"></Route>
    </Routes>
  );
}

export default RouterComponent;
