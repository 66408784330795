import React from "react";

export function WaveComponent() {
    return(
        <div className="waveWrapper waveAnimation">
        <svg viewBox="0 0 500 150" preserveAspectRatio="none">
            <path d="M-5.07,73.52 C149.99,150.00 299.66,-102.13 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{stroke:"none"}}></path>
        </svg>
    </div>
    )

}