
import ContactDetailComponent from "../Component/ContactDetailComponent";
import { App } from "./../App";

import BreadcrumbComponent from './../Component/breadcrumbComponent';
import { ContactFormComponent } from './../Component/formulaires/ContactFormComponent';


function ContactPage(Props) {
  return (
    <>
      <BreadcrumbComponent titre={"Contact"}></BreadcrumbComponent>
      <section className="w3l-contact-1 pb-5" id="contact">
        <div className="contacts-9 py-lg-5 py-md-4">
          <div className="container">
            <div className="d-grid contact-view">
              <ContactDetailComponent></ContactDetailComponent>
             <ContactFormComponent></ContactFormComponent>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
const Contact = () => <App component={ContactPage} />;

export default Contact;
