import React, { useRef } from "react";
import { Formik, Form } from "formik";
import { TextField, TextArea } from "../TextField";
import * as Yup from "yup";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
//import emailjs from '@emailjs/browser';

export const ContactFormComponent = () => {

  const validate = Yup.object({
    user_first_name: Yup.string()
      .min(2, "Doit comporter au moins 2 caractères")
      .required("Ce champ est obligatoire"),
    user_email: Yup.string()
      .email("L'adresse électronique n'est pas valide")
      .required("L'adresse électronique est obligatoire"),
    user_object: Yup.string().required("Ce champ est obligatoire"),
    user_message: Yup.string().required("Ce champ est obligatoire"),
  });

  function sendMail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_i8og4zp",
        "template_z9sk4de",
        e.target,
        "8ud3G15siQADLo7Ar"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);

        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
      e.target.reset();


  }

  return (
    <Formik
      initialValues={{
        user_first_name: "",
        user_email: "",
        user_object: "",
        user_message: "",
      }}
      validationSchema={validate}
      onSubmit ={(values, action) => {
        //action.setSubmitting(false);
        //action.resetForm();
        console.log(action);
      }}

    >
      {(formik) => (
        <div className="map-content-9">
          <h5 className="mb-sm-4 mb-3">Laissez-nous un message</h5>
          <Form  onSubmit ={(e) => {
                e.preventDefault();
                sendMail(e)
              }}>
            <div className="twice-two">
              <TextField
                name="user_first_name"
                type="text"
                id="w3lName"
                placeholder="Nom & prénoms"
              />
              <TextField name="user_email" type="email" placeholder="E-mail" />
            </div>

            <div className="twice">
              <TextField
                name="user_object"
                type="text"
                id="w3lSubject"
                placeholder="Objet"
              />
              <TextArea
                name="user_message"
                type="text"
                id="w3lMessage"
                placeholder="Votre Message"
              />
            </div>

            <div className="text-right">
              <button type="submit" className="btn btn-edar  ml-2">
                J'envoie mon message
              </button>
              <div className="mt-5"></div>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};
