import React from "react";
import axios from "axios";
import baseUrl from './../API/baseUrl';
import { useField,ErrorMessage,Field } from 'formik';

//TextArea forma
export const TextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="mb-2">
      <label htmlFor={field.name}>{label}</label>
      <textarea
        className={`form-control shadow-none ${
          meta.touched && meta.error && "is-invalid"
        }`}
        {...field}
        {...props}
        autoComplete="off"
      />
    </div>
  );
};

// TextField  field forma
export const TextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="mb-2">
      <label htmlFor={field.name}>{label}</label>
      <input
        className={`form-control shadow-none ${
          meta.touched && meta.error && "is-invalid"
        }`}
        {...field}
        {...props}
        autoComplete="off"
      />
      <ErrorMessage component="div" name={field.name} className="error" />
    </div>
  );
};

// ReclamField field forma
export const ReclamField = ({ label,...props }) => {
  const [field, meta, helpers] = useField(props);
  return (
    <div className="mb-2">
      <label htmlFor={field.name}>{label}</label>
      <input
        className={`form-control shadow-none ${
          meta.touched && meta.error && "is-invalid"
        }`}
        {...field}
        {...props}
        autoComplete="off"
      />
      <ErrorMessage component="div" name={field.name} className="error" />
    </div>
  );
};

// for select complaint subject type
export class SelectSubject extends React.Component {
  componentDidMount() {
    this.LoadComplaint();
  }

  constructor() {
    super();

    this.state = {
      subjects: [],
    };
  }

  LoadComplaint = async () => {
    try {
      const url = `${baseUrl}/complaint/subject-types`;
      const response = await axios.get(url);
      if (response.status === 200) {
        this.setState({
          subjects: response.data.subjects,
        });


      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  render = () => {
    const { name,} = this.props;
    /* console.log("render", this.state.subjects); */
    return (
      <div className="mb-2">
        <Field
          as="select"
          name={name}
          className="form-select"
          aria-label=" Selectionnez le motif de la réclamation"
        >
          {this.state.subjects.map((item, index) => (
            <option value={item.label} key={index}>
              {item.label}
            </option>
          ))}
        </Field>
        <ErrorMessage component="div" name={name} className="error" />
      </div>
    );
  };
}
