import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField } from "../TextField";
import baseUrl from "./../../API/baseUrl";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const validate = Yup.object({
  user_last_name: Yup.string()
    .min(2, "Doit comporter au moins 2 caractères")
    .required("Ce champ est obligatoire"),
  user_first_name: Yup.string()
    .min(2, "Doit comporter au moins 2 caractères")
    .required("Ce champ obligatoire"),
  user_email: Yup.string()
    .email("L'adresse électronique n'est pas valide")
    .required("L'adresse électronique est obligatoire"),
  user_phone_number_prefix: Yup.string().required(
    "L'indicatif est obligatoire"
  ),
  user_phone_number: Yup.number("Entrez un numéro valide")
    .required("Le numéro de téléphone est obligatoire")
    .positive()
    .integer(),
});

/* const onSubmit = ( values, onSubmitProps) =>{
  console.log('Form data', values)
  console.log('submit props', onSubmitProps)
  onSubmitProps.setSubmitting(false)
  onSubmitProps.resetForm()
} */

export default class Formachatcoupon extends React.Component {
  MySwal = withReactContent(Swal);

  alertContent = (title, text, icon = "success") => {
    this.MySwal.fire({
      title: title,
      text: text,
      icon: icon,
      timer: 5000,
      timerProgressBar: true,
      showConfirmButton: false,
    });
  };

  PostCustomer = async (data) => {
    try {
      const url = `${baseUrl}/payment/link-generation`;
      const response = await axios.post(url, data);

      /* console.log("response", response); */
      if (response.status === 200) {
        console.log("PostCustomer", response.data.payment_url);
        window.open(response.data.payment_url, "_blank");
      }
    } catch (error) {
      console.log(error);
      this.alertContent(
        "Oups",
        "une erreur est survenue, Veuillez saisir manuellemnt chaque champ!",
        "warning"
      );
    }
  };

  render = () => {
    return (
      <Formik
        initialValues={{
          user_last_name: "",
          user_first_name: "",
          user_email: "",
          user_phone_number_prefix: "+225",
          user_phone_number: "",
        }}
        validationSchema={validate}
        onSubmit={(values, action) => {
          this.PostCustomer(values).then(() => {
            action.setSubmitting(false);
            action.resetForm();
          });
         // console.log(values);
         this.alertContent(
          "Félicitations",
          " Veuillez autoriser votre navigateur à acéder au popup svp!",
          "success"
        );
        }}
      >
        <div className="map-content-9">
          <h5 className="mb-sm-4 mb-3"> Achetez votre coupon </h5>
          <Form mehod="post">
            <div className="twice">
              {/* <SelectOption label="Nature du document" name="user_document_choice" /> */}
              <TextField label="Nom" name="user_last_name" type="text" />
              <TextField label="Prénom(s)" name="user_first_name" type="text" />
              <TextField label="E-mail" name="user_email" type="email" />
            </div>
            <div className="twice-two">
              <TextField
                label="Indicatif"
                name="user_phone_number_prefix"
                type="text"
              />
              <TextField
                label="Téléphone"
                name="user_phone_number"
                type="text"
              />
            </div>
            <div className="text-right">
              <button className="btn btn-danger mr-2" type="reset">
                Effacer
              </button>
              <button type="submit" className="btn btn-edar  ml-2">
                Continuer
              </button>
              <div className="mt-5"> </div>
            </div>
          </Form>
        </div>
      </Formik>
    );
  };
}
