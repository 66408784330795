import React from "react";
import { Link } from "react-router-dom";

export default function BoutonContactComponent(props) {
  return (
    <Link
      to={"/contact"}
      className="btn btn-style btn-outline-light mt-sm-5 mt-4 mr-2">
      Contactez-nous
    </Link>
  );
}
