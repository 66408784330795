import React from "react";
export default class ContactDetailComponent extends React.Component {
  render = () => {
    return (
      <div className="cont-details">
        <h3 className="title-big mb-4">N'hésitez pas à nous contacter</h3>
        <p className="mb-sm-5 mb-4">
          Pour toutes vos préoccupations, adressez-vous à l'une de nos adresses
          ou obtenez un rendez-vous avec nous, et notre équipe se rendra disponible
          pour vous satisfaire dans les meilleurs délais.
        </p>

        <div className="cont-top">
          <div className="cont-left text-center">
            <span className="fa fa-map-marker text-success"></span>
          </div>
          <div className="cont-right">
            <h6>Notre Siège</h6>
            <p className="pr-lg-5">
             Rue J41 2 Plateaux, Cocody,Abidjan | Côte d'Ivoire.
            </p>
          </div>
        </div>
        <div className="cont-top margin-up">
          <div className="cont-left text-center">
            <span className="fa fa-phone text-success"></span>
          </div>
          <div className="cont-right">
            <h6>Appelez-Nous </h6>
            <p>
              <a href="tel:+(225) 27 22 235 347">(+225) 2722 235 347</a>
            </p>
            {/* <p>
              <a href="tel: +(225) 01 01 017 811"> (+225) 0101 017 811</a>
            </p> */}
          </div>
        </div>
        <div className="cont-top margin-up">
          <div className="cont-left text-center">
            <span className="fa fa-envelope-o text-success"></span>
          </div>
          <div className="cont-right">
            <h6>Envoyez-Nous Un Mail</h6>
            <p>
              <a href="mailto:infos@bvs-afrique.com" className="mail">
              infos@bvs-afrique.com
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  };
}
