import React from "react";
import { Link } from "react-router-dom";

export default function BoutonCallActionComponent(props) {
  return (
    <Link
      to={"/paiement-du-coupon"}
      className="btn btn-style btn-edar mt-sm-5 mt-4">
      Payer un coupon
    </Link>
  );
}
