import React from "react";
import { Formik, Form, } from "formik";
import { TextField } from "../TextField";
import * as Yup from "yup";
import axios from "axios";
import baseUrl from "./../../API/baseUrl";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export class Formvouchersearch extends React.Component {
  MySwal = withReactContent(Swal);

  alertContent = (title, text, icon = "success") => {
    this.MySwal.fire({
      title: title,
      text: text,
      icon: icon,
      timer: 5000,
      timerProgressBar: true,
      showConfirmButton: false,
    });
  };
  state = {
    Receipts: null,
  };
  componentDidMount() {
    /* this.LoadReceipt() */
  }
  LoadReceipt = async (values) => {
    console.log("LoadReceipt", values);
    try {
      const url = `${baseUrl}/receipt`;
      const response = await axios.get(url, {
        params: { receipt_number: values.receipt_number },
      });
      /*   console.log("response", response); */

      if (response.status === 200) {
        /*  console.log("LoadReceipt", response.data.token_code); */
        this.setState({ Receipts: response.data.token_code });
      } else {
      }
    } catch (error) {
      this.alertContent(
        "Désolé",
        "le numéro de votre reçu est invalide",
        "error"
      );
      console.log(error);
    }
  };
  render = () => {
    const validate = Yup.object({
      receipt_number: Yup.string().required("Ce champ est obligatoire"),
    });

    return (
      <Formik
        initialValues={{
          receipt_number: "",
        }}
        validationSchema={validate}
        onSubmit={(values,action) => {
          /* console.log(values); */
          this.LoadReceipt(values).then(()=>{action.setSubmitting(false);
            action.resetForm();});
;
        }}
      >
        {(formik) => (
          <div>
            <h1 className="my-4 font-weight-bold-display-4">
              Recherchez votre coupon
            </h1>
            <h6 className="my-1 font-weight-normal-display-2">
              Entrez la reférence de la transaction
            </h6>
            {this.state.Receipts ? (
              <div className="alert alert-success" role="alert">
                {" "}
                Le code de votre Coupon est:
                <ul>
                  <li>{this.state.Receipts}</li>
                </ul>
              </div>
            ) : (
              " "
            )}

            <Form method="post">
              <TextField name="receipt_number" type="text" />
              <div className="text-center">
                <button className="btn btn-danger mr-2" type="reset">
                  Annuler
                </button>
                <button type="submit" className="btn btn-edar  ml-2">
                  Vérifier
                </button>
                <div className="mt-2"></div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    );
  };
}
