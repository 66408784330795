import React from "react";
import { CardList } from "./CardComponent";

export default class FeatureComponent extends React.Component {
  render = () => {
    return (
      <section className="w3l-features py-5" id="facilities">
        <div className="call-w3 py-lg-12 py-md-4 py-2">
          <div className="container">
            <div className="row main-cont-wthree-2">
              <div className="col-lg-4 mb-5 feature-grid-left">
                <h3 className="title-big mb-4">Authenticité </h3>
                <p className="edar-cont-justify">
                  Si vous faites des affaires ou voyagez à l’étranger, il se
                  peut que vous ayez besoin de prouver l’authenticité de
                  documents officiels délivrés en Côte d'Ivoire. Les consulats
                  et les ambassades demandent des certificats d’authenticité
                  pour s’assurer que ces divers documents sont valides. BVS
                  authentifie des documents en vérifiant l'existence, le sceau,
                  le tampon et la signature
                </p>
                <p className="edar-cont-justify">
                  La légalisation d'un document établi à l'étranger peut être
                  exigée pour faire des démarches. Par exemple, pour demander
                  une nationalité. La légalisation atteste les informations
                  suivantes : Véracité de la signature Fonction et autorité du
                  signataire Identité du sceau ou timbre Un cachet officiel de
                  légalisation est ajouté sur le document.
                </p>
              </div>
              <div className="col-lg-8 feature-grid-right mt-lg-0 mt-5">
                <div className="call-grids-w3 d-grid">
                  <CardList/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
}
