import React from "react";
import { Link } from "react-router-dom";
import { authentification1 } from "../imageassets";

export default class FooterComponent extends React.Component {
  render = () => {
    return (
      <>
        <section className="w3l-footer-29-main">
          <div className="footer-29 py-5">
            <div className="container py-md-4">
              <div className="row footer-top-29">
                <div className="col-lg-4 col-md-6 col-sm-5 col-6 footer-list-29 footer-1 mt-sm-0 mt-5">
                  <ul>
                    <h6>
                      <Link className="navbar-brand" to={"/"}>
                        <img
                          src={authentification1}
                          alt="logo de Verification.ci"
                          className="img-fluid"
                          width={"60%"}
                        />
                      </Link>
                    </h6>
                    <li>
                      <p >Service d'authentification de document et de vérification d'antécédents</p>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-7 footer-list-29 footer-2 pr-lg-5">
                  <h6 className="footer-title-29">Contacts </h6>
                  <p className="my-2">Rue J41, 2 Plateaux, Cocody,Abidjan | CIV</p>
                  <p className="my-2">
                    <Link to="tel:+(225) 27 22 235 347">(+225) 2722 235 347</Link>
                  </p>
                  <p className="my-2">
                    <Link to="mailto:infos@bvs-afrique.com" className="mail">
                      infos@bvs-afrique.com
                    </Link>
                  </p>
                  <div className="main-social-footer-29 mt-4">
                    <Link to=" " className="facebook">
                      <span className="fa fa-facebook"></span>
                    </Link>
                    <Link to="#linkedin" className="linkedin">
                      <span className="fa fa-linkedin"></span>
                    </Link>
                    <Link to="#twitter" className="twitter">
                      <span className="fa fa-twitter"></span>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-7 footer-list-29 footer-3 pr-lg-5">
                <h6 className="footer-title-29">Menu</h6>
                  <>
                    <p>
                      <Link to={"/"}>Accueil</Link>
                    </p>
                    <p>
                    <Link to={"/paiement-du-coupon"}>Acheter un coupon</Link>
                    </p>
                    <p>
                    <Link to={"/consulter-recu"}>Consulter son reçu</Link>
                    </p>
                    <p>
                    <Link to={"/reclamation"}>Réclamation</Link>
                    </p>
                    <p>
                    <Link to={"/contact"}>Contacts</Link>
                    </p>

                  </>
                </div>

              </div>
            </div>
          </div>
        </section>
        <section className="w3l-copyright text-center">
          <div className="container">
            <p className="copy-footer-29">
              © 2022. All rights
              reserved.Design by {" "} BVS-Afrique
              <Link to ="https:bvs-afrique.com" target="_blank">

              </Link>
            </p>
          </div>
        </section>

      </>
    );
  };
}
