import React from "react";
import "../js/owl.carousel";
import $ from "jquery";
import Carousel from "react-bootstrap/Carousel";
import { banner1_1, banner1_2 } from "./imageassets";
import { Link } from "react-router-dom";

function Mainslider() {
  return (
    <section className="w3l-main-slider ">
      <div className="companies20-content carousel slide carousel-fade">
        <Carousel className="owl-one owl-theme carousel-inner">
          <Carousel.Item className="carousel-item" data-bs-interval="7000">
            <img
              className="d-block w-100 slider-img-edar"
              src={banner1_1}
              alt="First slide"
            />
            <Carousel.Caption className="carousel-caption d-none d-md-block">
              <h1 className="slider-title-edar">
                BIENVENUE SUR LE PORTAIL D'AUTHENTIFICATION
              </h1>
              <br/>
              <p className="slider-sub-title-edar">
                Soumettez vos documents administratifs, judiciaires, vos pièces
                d'identité et vos diplômes à une authentification en ligne
              </p>
              <span className="cont-center-edar">
                  <Link
                    to={"/paiement-du-coupon"}
                    className="btn btn-style btn-edar mt-sm-5 mt-4"
                  >
                    Payer un coupon
                  </Link>
                </span>
            </Carousel.Caption>
          </Carousel.Item>


          <Carousel.Item className="carousel-item" data-bs-interval="7000">
            <img
              className="d-block w-100 slider-img-edar"
              src={banner1_2}
              alt="Second slide"
            />
            <Carousel.Caption className="carousel-caption d-none d-md-block">
              <h1 className="slider-title-edar">AUTHENTIFIEZ VOS DOCUMENTS</h1>
              <p className="slider-sub-title-edar">
              <span className="cont-center">
                  <Link
                    to={"/paiement-du-coupon"}
                    className="btn btn-style btn-success mt-sm-5 mt-4"
                  >
                    Payer un coupon
                  </Link>
                </span>
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </section>
  );
}

export default Mainslider;
