import React from "react";
import "../Component/imageassets";
import { Link } from "react-router-dom";
import {
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
} from "../Component/imageassets";

export default class StepComponent extends React.Component {
  render = () => {
    return (
      <section
        className="w3l-team py-5"
        id="team"
        style={{ backgroundColor: "#FCB228" }}
      >
        <div className="call-w3 py-lg-5 py-md-4">
          <div className="container">
            <h1 className="edar-h1 cont-center mb-4">
              Comment faire authentifier un document?
            </h1>
            <p className="edar-p mb-sm-5 mb-4">
              Pour effectuer la demande d'authentification de vos documents
              administratifs, de vos Pièces d'identité et de vos diplômes,
              suivez les étapes suivantes :
            </p>
            <div className="row main-cont-wthree-2 mt-4">
              <div className="col-lg-5 feature-grid-right">
                <br />
                <div>
                  {/* etape 1 */}

                  <div className="cont-center">
                    <h5>1. Achetez votre coupon</h5>
                    <span>
                      <img
                        className="img-fluid"
                        width={"13%"}
                        src={step1}
                        alt="Acheter votre coupon"
                      />
                    </span>
                  </div>
                  <div className="vr mt-2"></div>

                  {/*  etape 2 */}

                  <div className="cont-center">
                    <h5>2. Obtenez la référence votre coupon</h5>
                    <span>
                      <img
                        className="img-fluid"
                        width={"13%"}
                        src={step2}
                        alt="obtenez votre coupon"
                      />
                    </span>
                  </div>
                  <div className="vr mt-2"></div>

                  {/* etape 3 */}

                  <div className="cont-center">
                    <h5>3. Connectez-vous | Créez un compte </h5>
                    <span>
                      <img
                        className="img-fluid"
                        width={"13%"}
                        src={step3}
                        alt="Login"
                      />
                    </span>
                  </div>
                  <div className="vr mt-2"></div>

                  {/* etape 4 */}

                  <div className="cont-center">
                    <h5>4. Saisissez vos informations</h5>
                    <span>
                      <img
                        className="img-fluid"
                        width={"13%"}
                        src={step4}
                        alt="utiliser votre coupon"
                      />
                    </span>
                  </div>
                  <div className="vr mt-2"></div>

                  {/* etape 5 */}

                  <div className="cont-center">
                    <h5>5. Téléversez votre document</h5>
                    <span>
                      <img
                        className="img-fluid"
                        width={"13%"}
                        src={step5}
                        alt="uploader le document"
                      />
                    </span>
                  </div>
                  <div className="vr mt-2"></div>

                  {/* etape 6 */}

                  <div className="cont-center">
                    <h5>6. Recevez le résultat</h5>
                    <span>
                      <img
                        className="img-fluid"
                        width={"13%"}
                        src={step6}
                        alt="Recevoir le resultat"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 feature-grid-left">
                <div className="map-content-9">
                  <div className="twice">
                    <div>
                      <h6>
                        1. Acheter votre coupon en ligne sur{" "}
                        <Link
                          to={"/paiement-du-coupon"}
                          className="text-success"
                        >
                          www.authentification.ci
                        </Link>
                      </h6>
                      <p className="cont-right">
                        Payez en ligne avec Wave, Orange Money, Moov Money, MTN
                        Mobile Money, Visa, Mastercard,
                      </p>
                    </div>

                    <div className="vr mt-2"></div>
                    <div>
                      <h6>2. Obtenez votre coupon</h6>
                      <p className="cont-center">
                        Consultez le sms et le mail envoyés par BVS-Afrique, ils
                        contiennent l'identifiant de votre coupon que vous venez
                        de payer; conservez bien cet identifiant.
                      </p>
                    </div>
                    <div className="vr mt-2"></div>
                    <div>
                      <h6>
                        3. Inscription / Connexion sur la plateforme{" "}
                        <a
                          href="https://preprod6.preprod-directverify.in/student"
                          className="text-success"
                          target={"_blank"}
                        >
                          Docswallet{" "}
                        </a>{" "}
                      </h6>
                      <p className="cont-right">
                        La plateforme (docswallet) vous invite à Créer un compte
                        si vous n'en avez pas, ou elle vous demande de vous
                        connecter si vous avez déjà un compte.
                      </p>
                    </div>
                    <div className="vr"></div>
                    <div>
                      <h6>4. Utilisez votre coupon</h6>
                      <p className="cont-right">
                        Saisissez les références de votre coupon pour le faire
                        valider et passer à l'étape suivante.
                      </p>
                    </div>
                    <div className="vr"></div>
                    <div>
                      <h6>5. Ajout du document à faire Authentfier</h6>
                      <p className="cont-right">
                        Assurez-vous d'avoir scanné votre document à faire
                        authentifier, puis téléversez le sur la plateforme.
                      </p>
                    </div>
                    <div className="vr"></div>
                    <div>
                      <h6>6. Résultat de la demande</h6>
                      <p className="cont-right">
                        Vous recevrez un sms et un mail une fois que votre
                        demande sera traitée.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
}
