
import React, { Component } from 'react';
/* import Chatbot from 'react-chatbot-kit';
import config from './config';
import ActionProvider from './ActionProvider';
import MessageParser from './MessageParser'; */
import ChatBot from 'react-simple-chatbot';
import { PropTypes } from 'prop-types';

class Review extends Component {
    constructor(props) {
      super(props);

      this.state = {
        name: '',
        gender: '',
        age: '',
      };
    }

    componentWillMount() {
      const { steps } = this.props;
      const { name, gender, age } = steps;

      this.setState({ name, gender, age });
    }

    render() {
      const { name, gender, age } = this.state;
      return (
        <div style={{ width: '100%' }}>
          <h3>Summary</h3>
          <table>
            <tbody>
              <tr>
                <td>Name</td>
                <td>{name.value}</td>
              </tr>
              <tr>
                <td>Gender</td>
                <td>{gender.value}</td>
              </tr>
              <tr>
                <td>Age</td>
                <td>{age.value}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
  }

  Review.propTypes = {
    steps: PropTypes.object,
  };

  Review.defaultProps = {
    steps: undefined,
  };


class SimpleForm extends React.Component {
    state = {
        opened: false
      }
      toggleFloating = ({ opened }) => {
        this.setState({ opened });
      }

    render = () => {
        const {opened} = this.state;
        const steps =[
            {
              id: '1',
              message: 'Hello, je suis Domi, Quel est votre nom?',
              trigger: '2',
            },
            {
              id: '2',
              user: true,
              trigger: '3',
            },
            {
              id: '3',
              message: 'Bienvenue {previousValue} Comment puis-je vous aider?',
              trigger: '4',
            },
            {
                id: '4',
                user: true,
                trigger: '5',
              },
              {
                id: '5',
                message: 'Veuillez Svp vous rendre à notre page contact',
               end: true,
              },
          ]
      return (
        <ChatBot
        steps={steps}
        floating={true}
        opened = {opened}
        toggleFloating={this.toggleFloating}
      />
      );
    }

  }

export default SimpleForm;