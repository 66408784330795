import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import baseUrl from "../../API/baseUrl";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ReclamField, TextArea, SelectSubject } from "./../TextField";

const validate = Yup.object().shape({
  subject_type: Yup.string().required("Selectionnez le type de réclamation"),
  message: Yup.string()
    .required("Ce champ est obligatoire")
    .max(1000, "nombre de ligne réservé atteint"),
  customer_last_name: Yup.string().required("Ce champ est obligatoire"),
  customer_first_name: Yup.string().required("Ce champ est obligatoire"),
  customer_phone_number_prefix: "+225",
  customer_phone_number: Yup.string().required(
    "Le numéro de téléphone est obligatoire"
  ),
  customer_payment_receipt_number: Yup.string(),
  customer_email: Yup.string().email(),
});

export default class Formvcomplaint extends React.Component {
  MySwal = withReactContent(Swal);

  //alerte
  alertContent = (title, text, icon = "success") => {
    this.MySwal.fire({
      title: title,
      text: text,
      icon: icon,
      timer: 4500,
      timerProgressBar: true,
      showConfirmButton: false,
    });
  };

  PostComplaint = async (data) => {
    try {
      const url = `${baseUrl}/complaint`;
      const response = await axios.post(url, data);
      /* console.log("response", response);*/
      console.log("PostComplaint", response.data);
      this.alertContent("Félicitation", "Votre message a bien été envoyé");
    } catch (error) {
      console.log(error);
      this.alertContent(
        "Oups",
        "une erreur est survenue lors de l'envoie, veuillez réessayer svp!",
        "warning"
      );
    }
  };

  render = () => {
    return (
      <Formik
        initialValues={{
          subject_type: "PAYMENT_FAIL",
          message: "",
          customer_last_name: "",
          customer_first_name: "",
          customer_phone_number_prefix: "+225",
          customer_phone_number: "",
          customer_email: "",
          customer_payment_receipt_number: "",
        }}
        /*     validationSchema={validate} */
        onSubmit={(values, action) => {
          this.PostComplaint(values).then(() => {
            action.setSubmitting(false);
            action.resetForm();
          });
          console.log(values);
        }}
      >
        {(formik) => (
          <div className="map-content-9">
            <h5 className="mb-sm-4 mb-3">Déposez une réclamation</h5>
            <Form method="post" id="complaint-post">
              <SelectSubject name="subject_type" type="text" />
              <div className="twice-two">
                <ReclamField
                  name="customer_last_name"
                  type="text"
                  label="Nom"
                />
                <ReclamField
                  name="customer_first_name"
                  type="text"
                  label="Prénom(s)"
                />
                <ReclamField
                  name="customer_phone_number_prefix"
                  type="text"
                  placeholder="+225"
                  label="Indicatif"
                />

                <ReclamField
                  name="customer_phone_number"
                  type="number"
                  placeholder="070501XXXX"
                  label="Numéro utilisé pour le paiement"
                />
              </div>

              <div className="twice">
                <ReclamField
                  name="customer_payment_receipt_number"
                  type="text"
                  placeholder="Ref.XXXXXXX"
                  label="Référence du paiement"
                />
                <ReclamField
                  name="customer_email"
                  type="email"
                  placeholder="monadresse@mail.com"
                  label="Adresse mail"
                />
                <TextArea
                  name="message"
                  type="text"
                  placeholder="Ecrivez ici"
                  label="Votre message"
                />
              </div>

              <div className="text-right">
                <button className="btn btn-danger mr-2" type="reset">
                  Effacer
                </button>
                <button type="submit" className="btn btn-edar  ml-2">
                  Envoyer
                </button>
                <div className="mt-5"></div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    );
  };
}
