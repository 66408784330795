import React from "react";
/*import { brand1, brand2, brand3, brand4, brand5 } from "./imageassets";*/
import { PartenerList } from './PartnerLogoComponent';

export default class PartnerComponent extends React.Component {
  render = () => {
    return (  <section className="w3l-clients py-5" id="clients">
    <div className="call-w3 py-md-4 py-2">
        <div className="container">
            <div className="company-logos text-center">
                <div className="row logos">
                <PartenerList></PartenerList>

                </div>
            </div>
        </div>
    </div>
</section>)
}
}