import React from "react";
import {
  document_legal,
  diplome,
  permis_de_conduire,
  document_administratif,
} from "./imageassets";

let cardList = [
  {
    id: 1,
    icon: document_administratif,
    titre: "Documents Administratifs",
    description:
      " Il peut s'agir des document suivants : dossier, rapport, étude, compte rendu, procès verbal, statistique, directive, instruction, circulaire, note et réponse ministérielle, avis ",
  },
  {
    id: 2,
    icon: diplome,
    titre: "Diplôme",
    description:
      "On parle d’authentification de diplôme lorsque ce diplôme a obtenu l’homologation nationale. "+
      "CEPE, BEPC, BACCALAURIAT, BTS, DUT, LICENCE, MASTER, DOCTORAT... ",
  },
  {
    id: 3,
    icon: permis_de_conduire,
    titre: "Documents d'Identité",
    description:
      "Le contrôle des documents d’identité permet de confirmer la légitimité et l’authenticité d’un document" +
      "d’identité tel qu’un passeport, une carte nationale d’identité ou encore un permis de conduire, en effectuant une série de contrôles. ",
  },
  {
    id: 4,
    icon: document_legal,
    titre: "Documents Judiciaires",
    description:
      "Une vérification du casier judiciaire,  certificat ou acte de naissance, un acte de mariage ou pacs,"+
      " acte de divorce, acte de décès, jugement, certificat de célibat, attestation de résidence ou domicile, à l’échelle"+
      "nationale pour vous fournir autant d’informations que possible sur les antécédents criminels d’une personne",
  },
];

export class CardList extends React.Component {
  render = () => {
    return (
    <>   {cardList.map((item) => {
            return (
              <CardElement
                key={item.id}
                icon={item.icon}
                titre={item.titre}
                description={item.description}
              ></CardElement>
            );
          })}</>

    );
  };
}

function CardElement({icon,titre,description }) {

  return (
    <div className="grids-1 box-wrap">
      <span className="icon">
        <img className="img-fluid w-40" src={icon} alt="" />
      </span>
      <br />
      <h5>{titre}</h5>
      <p className="cont-center">{description}</p>
    </div>
  );
}
